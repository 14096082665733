/** @format */

import { styled } from 'styled-components';
import { breakpointUpTo } from '../../theme/mixins';
import { Container } from '../ui/Layout';

const StyledContainer = styled(Container)`
  padding: 6rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${breakpointUpTo.lg`
    padding: 3rem 0;
  `}
`;

const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 1.875rem;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100%) */

  ${breakpointUpTo.lg`
    border-radius: 0.9375rem;
  `}

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 2rem;
`;

interface Props {
  videoURL: string;
}
export const SectionVideo = ({ videoURL }: Props) => {
  return (
    <StyledContainer>
      <StyledWrapper>
        <iframe
          width="560"
          height="315"
          src={videoURL}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </StyledWrapper>
    </StyledContainer>
  );
};
